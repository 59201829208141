import { startCase, camelCase, toLower } from 'lodash';

export abstract class StringHelper {
  static extractInitialsName(fullname: string): string {
    if (!fullname) {
      return '';
    }

    const nameParts = fullname
      .toLowerCase()
      .replace(/[^A-Za-z\s]+/g, '')
      .split(' ');
    const prepositions = ['de', 'da', 'do', 'das', 'dos', 'e'];
    const filteredNameParts = nameParts.filter((item) => item && !prepositions.includes(item));

    if (!filteredNameParts.length) {
      return '';
    }

    const lastSegment = filteredNameParts.length === 1 ? 0 : 1;
    return filteredNameParts[0][0] + filteredNameParts[lastSegment][0];
  }

  static toTitleCase(value: string): string {
    const regex = RegExp(/ de | da | do | das | dos | e /gi);
    return startCase(camelCase(value)).replace(regex, (x) => toLower(x));
  }

  static formatFilename(value: string): string {
    return value.replace(/[^\w/_/./-]/gi, '-');
  }

  static getNSentences(value: string, n: number): string {
    if (!value) return '';
    const sentences: string[] = value.split('.');
    let res = '';

    for (let i = 0; i < n && i < sentences.length; i += 1) {
      res += `${sentences[i].trim()}.`;
    }

    return res;
  }
}
